@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@font-face {
  font-family: 'SendingZpEm3';
  src: url('./fonts/Sending-ZpEm3.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Quentin';
  src: url('./fonts/Quentin.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family:'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Roboto','Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.sidebar-item {
  display: flex;
  align-items: center;
  padding: 1rem;
  transition: all 0.3s ease-in-out;
}

.sidebar-icon {
  font-size: 1.5rem;
  margin-right: 1rem;
}

.sidebar-text {
  display: none;
}

.hover\:w-64:hover .sidebar-text {
  display: inline;
}

.highlight {
  background-color: #f0f0f0;
}

.font-sending {
  font-family: 'SendingZpEm3', sans-serif;
}
