/* Stilovi za kalendar */
.custom-calendar {
    background-color: #fff;
    border:none;
    padding: 10px;
  }
  
  /* Stil za aktivni dan */
  .custom-calendar .react-calendar__tile--active {
    background-color: #c59d5f !important; /* Zlatna boja za aktivni dan */
    color: black;
  }
  
  /* Stil za hover dan */
  .custom-calendar .react-calendar__tile:hover {
    background-color: #c59d5f !important; /* Tamnija zlatna boja za hover */
    color: black;
  }
  
  /* Stil za specifične dane (primer za ponedeljke) */
  .custom-calendar .highlight {
    background-color: #fbe8e8; /* Svijetla crvena boja za specifične dane */
    color: #d32f2f; /* Tamnija crvena za tekst *//* Blago zaobljeni uglovi */
    padding: 0.5rem; /* Malo prostora unutar oznake */
  }
  
  
  