.content {
    color: #333;
  }
  
  /* Stil za bold */
  .content strong {
    font-weight: bold;
  }
  
  /* Stil za italic */
  .content em {
    font-style: italic;
  }
  
  /* Stil za underline */
  .content u {
    text-decoration: underline;
  }
  
  /* Stil za blockquote */
  .content blockquote {
    font-style: italic;
    border-left: 4px solid #ccc;
    padding-left: 1em;
    color: #555;
  }
  
  /* Stil za numerisane liste */
  .content ol {
    list-style: decimal;
    padding-left: 1.5em;
    margin-bottom: 1em;
  }
  
  /* Stil za navođenje */
  .content ul {
    list-style: disc;
    padding-left: 1.5em;
    margin-bottom: 1em;
  }
  
  /* Stil za linkove */
  .content a {
    color: #007bff;
    text-decoration: underline;
  }
  
  /* Stil za slike */
  .content img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 1em 0;
  }
  
  /* Stil za video */
  .content video {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 1em 0;
  }
  
  /* Stil za formula */
  .content .ql-formula {
    font-size: 1.2em;
    margin: 1em 0;
  }
  
  /* Stil za header */
  .content h1, .content h2, .content h3, .content h4, .content h5, .content h6 {
    margin-top: 1em;
    margin-bottom: 0.5em;
    font-weight: bold;
  }

  .content pre {
    background-color: #2d2d2d !important; /* Tamno siva pozadina za kod */
    border-radius: 4px; /* Zaobljeni uglovi */
    padding: 1em; /* Unutrašnji razmak oko koda */
    overflow-x: auto; /* Horizontalno pomeranje za duže kodove */
    margin: 1em 0; /* Spoljni razmak oko `code` bloka */
    color: #f8f8f2; /* Svetlo siva boja teksta */
    font-family: 'Courier New', Courier, monospace; /* Monospace font za kod */
    font-size: 0.95em; /* Malo manja veličina teksta */
    white-space: pre; /* Održava razmake i prelome linija */
  }
  
  .content .ql-code-block-container .ql-code-block {
    /* Stilizacija specifičnih klasa ako je potrebno */
    color: #f8f8f2; /* Svetlo siva boja teksta */
    font-family: 'Courier New', Courier, monospace; /* Monospace font za kod */
    font-size: 0.95em; /* Malo manja veličina teksta */
    white-space: pre; /* Održava razmake i prelome linija */
  }
  